import React from "react";
import Logo from "../images/logos/GogreenCOLOR2-01.png";
import { Helmet } from "react-helmet";
import Favicon from "../images/logos/Gogreen-favicon.png";

const ThankYouGeneral = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Go Green Credits | Thank You</title>

        <meta
          name="description"
          content="Go Green Energy can provide green energy to customers in a multitude of ways from many renewable energy services and products to offsetting credits. Check out our services pages to learn more about how to go green! "
        />
        <link rel="icon" href={Favicon} />
        <link rel="icon" href={Favicon} />
      </Helmet>
      <main class="mt-10 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 lg:mt-16">
        <div class="text-center">
          <img class="mx-auto w-1/2" src={Logo} alt="logo" />
          <div className="mx-auto flex w-6/12 mb-4"></div>
          <h2 class="-mt-6 text-2xl md:text-4xl tracking-tight md:leading-12 font-extrabold text-fadedGreen sm:text-5xl sm:leading-none md:text-6xl">
            Your Submission was received successfully!
          </h2>
          <p class="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
            One of our representatives at Go Green will get in touch with you in
            24-48 hours if needed, thank you for your patience.
          </p>

          <div class="rounded-md">
            <div class="mt-10 rounded-md sm:flex-shrink-0">
              <a
                href="/"
                class="w-full px-16 py-5 text-lg font-medium rounded-md text-white bg-gradient-to-r from-neonGreen to-fadedGreen hover:bg-emerald-800 focus:outline-none focus:ring-neonGreen"
              >
                Return Home
              </a>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default ThankYouGeneral;
